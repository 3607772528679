import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { QuoteNode } from '@lexical/rich-text';
import { Klass, LexicalNode } from 'lexical';
import { MentionNode } from './MentionNode';

const DefaultNodes: Array<Klass<LexicalNode>> = [
  ListNode,
  ListItemNode,
  LinkNode,
  AutoLinkNode,
  CodeNode,
  CodeHighlightNode,
  QuoteNode,
  MentionNode,
];
export default DefaultNodes;
