const RichTextEditorTheme = {
  ltr: 'ltr',
  rtl: 'rtl',
  placeholder: 'richtext-placeholder',
  paragraph: 'richtext-paragraph',
  quote: 'richtext-quote',
  heading: {
    h1: 'richtext-heading-h1',
    h2: 'richtext-heading-h2',
    h3: 'richtext-heading-h3',
    h4: 'richtext-heading-h4',
    h5: 'richtext-heading-h5',
  },
  list: {
    nested: {
      listitem: 'richtext-nested-listitem',
    },
    ol: 'richtext-list-ol',
    ul: 'richtext-list-ul',
    listitem: 'richtext-listitem',
  },
  image: 'richtext-image',
  link: 'richtext-link',
  text: {
    bold: 'richtext-text-bold',
    italic: 'richtext-text-italic',
    overflowed: 'richtext-text-overflowed',
    hashtag: 'richtext-text-hashtag',
    underline: 'richtext-text-underline',
    strikethrough: 'richtext-text-strikethrough',
    underlineStrikethrough: 'richtext-text-underlineStrikethrough',
    code: 'richtext-text-code',
  },
};

export default RichTextEditorTheme;
