import { IconButton } from '@chakra-ui/react';

type ToolbarButtonProps = {
  icon: React.ReactElement;
  isDisabled?: boolean;
  isActive?: boolean;
  ariaLabel: string;
  onClick: () => void;
};

const ToolbarButton = (props: ToolbarButtonProps) => {
  const { icon, isDisabled, isActive, ariaLabel, onClick } = props;
  return (
    <IconButton
      icon={icon}
      isDisabled={isDisabled}
      isActive={isActive}
      aria-label={ariaLabel}
      size='md'
      variant={isActive ? 'solid' : 'ghost'}
      colorScheme='gray'
      onClick={onClick}
    />
  );
};
export default ToolbarButton;
