import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import AutoLinkPlugin from './AutoLink';
import CodeHighlightPlugin from './CodeHighlight';

const CommonPlugins = () => {
  return (
    <>
      <AutoLinkPlugin />
      <ListPlugin />
      <CheckListPlugin />
      <TabIndentationPlugin />
      <CodeHighlightPlugin />
      <LinkPlugin />
      <ClearEditorPlugin />
    </>
  );
};

export default CommonPlugins;
